import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import AboutUs from "../templates/about-us";

export const query = graphql`
  query AboutUsPageQuery {
    sanityAboutUsSettings {
      title
      description
      keywords
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
      teamMembers {
        id
        title
        _rawBody
        mainImage {
          asset {
            _id
          }
          alt
        }
      }
    }
    sanityCompanyInfo {
      name
      address1
      address2
      zipCode
      city
      country
      email
      vatID
      iban
      bankName
      registeredNumber
    }
  }
`;

const AboutUsPage = (props) => {
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const sanityAboutUsSettings = (data || {}).sanityAboutUsSettings;
  const sanityCompanyInfo = (data || {}).sanityCompanyInfo;

  return (
    <AboutUs
      sanityAboutUsSettings={sanityAboutUsSettings}
      sanityCompanyInfo={sanityCompanyInfo}
    />
  );
};

export default AboutUsPage;
