import React from "react";
import BlockContent from "../components/block-content";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

export default function TeamMember(props) {
  const { teamMember, rotateId, rotateStyle } = props;
  const teamMemberBody = teamMember._rawBody
    ? teamMember._rawBody
    : teamMember.body
    ? teamMember.body
    : [];
  return (
    <>
      <div
        style={rotateId === "rotate" ? rotateStyle : null}
        id={rotateId}
        className="clear"
        key={teamMember.id}
      >
        <div className="col1_2">
          <img
            src={imageUrlFor(buildImageObj(teamMember.mainImage)).url()}
            alt={teamMember.mainImage.alt}
          />
        </div>
        <div className="col1_2">
          <h2>{teamMember.title}</h2>
          <p>
            <BlockContent blocks={teamMemberBody} />
          </p>
        </div>
      </div>
      <hr />
    </>
  );
}
