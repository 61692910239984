import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import ParallaxContainer from "../components/parallax-container";
import TeamMember from "../components/Team";
import CompanyInfo from "../components/CompanyInfo";

export const aboutUsGROQ = `
*[_id == 'companyInfo' || _id == $id] {
  _id,
  _type,
  title,
  description,
  keywords,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
  teamMembers[]->{
    _id,
    title,
    body,
    mainImage {
      alt,
      asset->
    }
  },
  name,
  address1,
  address2,
  zipCode,
  city,
  country,
  vatID,
  iban,
  bankName,
  registeredNumber
}`;

export default function AboutUs(props) {
  const { sanityAboutUsSettings, sanityCompanyInfo } = props;
  return (
    <Layout>
      <SEO
        title={sanityAboutUsSettings.title}
        description={sanityAboutUsSettings.description}
        keywords={sanityAboutUsSettings.keywords}
      />
      <main id="main" className="site-main" role="main">
        <div id="aboutUs">
          <div style={{ overflow: "hidden" }} className="fullWidth">
            <ParallaxContainer images={sanityAboutUsSettings.parallaxImages} />
            <div id="homeBanner">
              <span>{sanityAboutUsSettings.title}</span>
            </div>
          </div>
          <div className="wp1220">
            <p className="center">{sanityAboutUsSettings.description}</p>
            <hr className="marBot" />
            <h1 className="centerAdress">Meet The Team</h1>
            <hr />

            {sanityAboutUsSettings.teamMembers &&
              sanityAboutUsSettings.teamMembers.map((teamMember, index) => {
                const rotateId = index % 2 === 0 ? null : "rotate";
                const rotateStyle = {
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row-reverse",
                };

                return (
                  <TeamMember
                    teamMember={teamMember}
                    rotateId={rotateId}
                    rotateStyle={rotateStyle}
                    index={index}
                  />
                );
              })}

            <CompanyInfo sanityCompanyInfo={sanityCompanyInfo} />
          </div>
        </div>
      </main>
    </Layout>
  );
}
