import React from "react";

export default function CompanyInfo(props) {
  const { sanityCompanyInfo } = props;
  return (
    <div className="clear">
      <div className="col1_2">
        <ul>
          <li id="bold">{sanityCompanyInfo.name}</li>
          <li>
            {`${sanityCompanyInfo.name}, ${""} ${sanityCompanyInfo.address1}`}
          </li>
          <li>
            {`${sanityCompanyInfo.address2},${""} ${
              sanityCompanyInfo.city
            },${""} ${sanityCompanyInfo.zipCode}`}
          </li>
          <li>
            <a href="mailto:aleph@alephinsights.com">
              {sanityCompanyInfo.email}
            </a>
          </li>
        </ul>
      </div>
      <div className="col1_2">
        <ul className="dataAbout">
          <li>VAT ID: {sanityCompanyInfo.vatID}</li>
          <li>{sanityCompanyInfo.iban}</li>
          <li>{sanityCompanyInfo.bankName}</li>
          <li>Reg. No. {sanityCompanyInfo.registeredNumber}</li>
        </ul>
      </div>
    </div>
  );
}
